<script>
export default {
  name: 'NotFound',
};
</script>

<template>
  <div class="flex flex-col justify-center">
    <p class="text-2xl">404 - Sidan kunde tyvärr inte hittas</p>
    <img class="max-w-sm mx-auto my-4" src="@/assets/404-bild.jpg"/>
  </div>
</template>
